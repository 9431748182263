<template>
  <div class="content">
    <el-card class="box-card">
      <div class="Untreated_top" slot="header">
        <span class="top_title">{{$t('客户联系人管理')}}</span>
        <div>
          <el-button type="primary" @click='dialogForm' v-if="user_role['customer_contacts/add']" size="small">{{$t('新增联系人')}}</el-button>
          <el-button type="primary" size="small" @click="getUserList(Page.page,1)">{{$t('导出EXCEL')}}</el-button>
        </div>
      </div>
      <div style="margin:15px 0;">
        <el-row :gutter="20">
          <el-col :span="6">
            <el-input :placeholder="$t('请输入客户联系人名称')" clearable v-model="name" class="input-with-select">
            </el-input>
          </el-col>
          <el-col :span="6">
            <el-select v-model="oc_id" clearable :placeholder="$t('请选择客户')">
              <el-option v-for="item in customer" :key="item.id
          " :label="item.name" :value="item.id
          ">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="6">
            <el-button icon="el-icon-search" @click="getUserList(1)"></el-button>
          </el-col>
        </el-row>

      </div>
      <el-table :data="UntreatedData" height="500" style="width: 100%" border>
        <el-table-column :label="$t('联系人ID')" prop="id" fixed="left" width="100" align="center" header-align="left">
        </el-table-column>
        <el-table-column :label="$t('客户名称')" prop="kehu_name" width="200">
        </el-table-column>
        <el-table-column :label="$t('联系人名称')" prop="username" width="200">
        </el-table-column>
        <el-table-column :label="$t('联系人手机号')" prop="phone" width="160">
        </el-table-column>
        <el-table-column :label="$t('联系人部门')" prop="department" width="160">
        </el-table-column>
        <el-table-column :label="$t('联系人职务')" prop="job" width="160">
        </el-table-column>
        <el-table-column :label="$t('联系人邮箱')" prop="email" width="200">
        </el-table-column>
        <el-table-column :label="$t('联系人QQ')" prop="qq" width="160">
        </el-table-column>
        <el-table-column :label="$t('联系人微信')" prop="wechat" width="160">
        </el-table-column>
        <!-- <el-table-column label="客户ID" prop="oc_id" width="100">
        </el-table-column> -->
        <el-table-column :label="$t('创建时间')" prop="created_at" width="200">
        </el-table-column>
        <el-table-column fixed="right" :label="$t('操作')" align="center" header-align="left" width="250">
          <template slot-scope="scope">
            <!-- <el-button type="text" size="small" @click="getDetail(scope)">查看详情</el-button> -->
            <el-button type="text" @click='dialogForm(scope,true)' v-if="user_role['customer_contacts/edit']" size="small">{{$t('修改信息')}}</el-button>
            <el-button type="text" @click="delRole(scope)" v-if="user_role['customer_contacts/delete']" size="small">{{$t('删除联系人')}}</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="Page.page"
        :page-size="Page.pagesize" layout="total, prev, pager, next, jumper" :total="Page.total"
        style="text-align: center;">
      </el-pagination>
    </el-card>
    <el-dialog :title="$t('添加客户联系人')" :visible.sync="addAdmin" width="80%">
      <el-row :gutter="20">
        <el-form :model="addform" label-position="right" label-width="100px" ref="addform">
          <el-col :span="12">
            <el-form-item :label="$t('联系人名称')" required prop="username" :rules="[{ required: true, message: $t('不能为空')}]">
              <el-input v-model="addform.username" clearable></el-input>
            </el-form-item>
          </el-col><!-- /客户联系人 -->
          <el-col :span="12">
            <el-form-item :label="$t('手机号')" required prop="phone" :rules="[{ required: true, message: $t('不能为空')}]">
              <el-input v-model="addform.phone"></el-input>
            </el-form-item>
          </el-col><!-- /手机号 -->
          <el-col :span="12">
            <el-form-item :label="$t('邮箱')" prop="email">
              <el-input v-model="addform.email" clearable></el-input>
            </el-form-item>
          </el-col><!-- /邮箱 -->
          <el-col :span="12">
            <el-form-item :label="$t('qq号')" prop="qq" :rules="[{ type: 'number', message: $t('必须是数字')}]">
              <el-input v-model="addform.qq" clearable></el-input>
            </el-form-item>
          </el-col><!-- /qq -->
          <el-col :span="12">
            <el-form-item :label="$t('微信号')" prop="wechat">
              <el-input v-model="addform.wechat" clearable></el-input>
            </el-form-item>
          </el-col><!-- /wechat -->
          <el-col :span="12">
            <el-form-item :label="$t('职务')" prop="job">
              <el-input v-model="addform.job" clearable></el-input>
            </el-form-item>
          </el-col><!-- /职务 -->
          <el-col :span="12">
            <el-form-item :label="$t('所属客户')" required prop="oc_id" :rules="[{ required: true, message: $t('请选择客户ID')}]">
              <el-select v-model="addform.oc_id">
                <el-option v-for="item in customer" :label="item.name" :value="item.id" :key="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col><!-- /客户ID -->
          <el-col :span="12">
            <el-form-item :label="$t('客户部门')" prop="department">
              <el-input v-model="addform.department" clearable></el-input>
            </el-form-item>
          </el-col><!-- /客户ID -->
        </el-form>
      </el-row>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addAdmin = false">{{$t('取消')}}</el-button>
        <el-button type="primary" @click="addMin">{{$t('确定')}}</el-button>
      </div>
    </el-dialog>
    <!--  -->
    <el-dialog :title="$t('修改客户联系人')" :visible.sync="edit" width="80%">
      <el-row :gutter="20">
        <el-form :model="editform" label-position="right" label-width="100px" ref="editform">
          <el-col :span="12">
            <el-form-item :label="$t('联系人名称')" required prop="username" :rules="[{ required: true, message: $t('不能为空')}]">
              <el-input v-model="editform.username" clearable></el-input>
            </el-form-item>
          </el-col><!-- /客户联系人 -->
          <el-col :span="12">
            <el-form-item :label="$t('手机号')" required prop="phone" :rules="[{ required: true, message: $t('不能为空')}]">
              <el-input v-model="editform.phone" disabled></el-input>
            </el-form-item>
          </el-col><!-- /手机号 -->
          <el-col :span="12">
            <el-form-item :label="$t('邮箱')" prop="email">
              <el-input v-model="editform.email" clearable></el-input>
            </el-form-item>
          </el-col><!-- /邮箱 -->
          <el-col :span="12">
            <el-form-item :label="$t('qq号')" prop="qq">
              <el-input v-model="editform.qq" clearable></el-input>
            </el-form-item>
          </el-col><!-- /qq -->
          <el-col :span="12">
            <el-form-item :label="$t('微信号')" prop="wechat">
              <el-input v-model="editform.wechat" clearable></el-input>
            </el-form-item>
          </el-col><!-- /wechat -->
          <el-col :span="12">
            <el-form-item :label="$t('职务')" prop="job">
              <el-input v-model="editform.job" clearable></el-input>
            </el-form-item>
          </el-col><!-- /职务 -->
          <el-col :span="12">
            <el-form-item :label="$t('客户名称')" required prop="oc_id" :rules="[{ required: true, message: $t('请选择客户ID')}]">
              <el-select v-model="editform.oc_id" disabled>
                <el-option v-for="item in customer" :label="item.name" :value="item.id" :key="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col><!-- /客户ID -->
          <el-col :span="12">
            <el-form-item :label="$t('客户部门')" prop="department">
              <el-input v-model="editform.department" clearable></el-input>
            </el-form-item>
          </el-col><!-- /客户ID -->
        </el-form>
      </el-row>
      <div slot="footer" class="dialog-footer">
        <el-button @click="edit = false">{{$t('取消')}}</el-button>
        <el-button type="primary" @click="editMin">{{$t('确定')}}</el-button>
      </div>
    </el-dialog>
    <!--  -->
    <!-- <el-dialog title="客户联系人详情" :visible.sync="Detail" width="80%">
      <el-row :gutter="20">
        <el-form label-position="right" label-width="80px">
          <el-col :span="24">
            <el-form-item label="权限">

            </el-form-item>
          </el-col>
        </el-form>
      </el-row>
      <div slot="footer" class="dialog-footer">
        <el-button @click="Detail= false">关 闭</el-button>
      </div>
    </el-dialog> -->
  </div>
</template>

<script>
export default {
  name: 'contact',
  data () {
    return {
      addAdmin: false,
      edit: false,
      UntreatedData: [],
      name: '',
      oc_id: '',
      addform: {
        username: '',
        phone: '',
        email: '',
        department: '',
        job: '',
        qq: '',
        wechat: '',
        oc_id: ''
      },
      editform: {
        id: '',
        username: '',
        phone: '',
        email: '',
        department: '',
        job: '',
        qq: '',
        wechat: '',
        oc_id: ''
      },
      Page: {
        pagesize: 10,
        page: 1,
        maxpage: 1,
        total: 2
      },
      isModify: false,
      Detail: false,
      customer: [],
      user_role: {}
    }
  },
  created () {
    this.getUserList()
    this.user_role = JSON.parse(window.localStorage.getItem('user_role'))
  },
  beforeUpdate () {
    this.customer = this.$store.state.ocList
  },
  methods: {
    // 打开表单
    dialogForm (item, isModify) {
      this.isModify = isModify
      if (isModify) {
        // console.log(item)
        this.editform = this.$common.forData2(item.row,this.editform)
        this.edit = true
      } else {
        this.addAdmin = true
      }
    },
    // 获取客户联系人列表
    getUserList (page, report) {
      let params = {
          page: page?page:this.Page.page,
          name:this.name,
          oc_id: this.oc_id,
        }
      if (report === 1) {
        params.is_report = report
        this.$common.postExcelFile(params, 'customer_contacts/lists')
        return
      }
      this.$axios.post('customer_contacts/lists',params).then((res) => {
        const Data = res.data
        // console.log(Data)
        if (Data.code === 0) {
          this.UntreatedData = Data.data.data
          this.Page = {
            pagesize: Data.data.pagesize,
            page: Data.data.page,
            maxpage: Data.data.maxpage,
            total: Data.data.total
          }
        } else this.$message.warning(Data.message)
      }).catch((error) => console.log(error))
    },
    // 添加客户联系人
    addMin () {
      let params = this.$common.forData(this.addform)
      this.$axios.post('customer_contacts/add', params).then((res) => {
        if (res.data.code === 0) {
          this.getUserList()
          this.addAdmin = false
          var that = this
          this.$common.resetField('addform', that)
          this.$message({
            type: 'success',
            message: res.data.message
          })
        } else {
          this.$message({
            type: 'warning',
            message: res.data.message
          })
          // console.log(res)
        }
      }).catch((error) => console.log(error))
    },
    // 修改客户联系人
    editMin () {
      let params = this.$common.forData(this.editform)
      this.$axios.post('customer_contacts/edit', params).then((res) => {
        // console.log(res)
        if (res.data.code === 0) {
          this.getUserList()
          this.edit = false
          var that = this
          this.$common.resetField('editform', that)
          this.$message({
            type: 'success',
            message: res.data.message
          })
        } else {
          this.$message({
            type: 'warning',
            message: res.data.message
          })
          // console.log(res)
        }
      }).catch((error) => console.log(error))
    },
    // 删除客户联系人
    delRole (scope) {
      // console.log(scope)
      let that = this
      this.$MessageBox.confirm(that.$t('此操作将永久删除该客户联系人, 是否继续?'),that.$t('提示'), {
        confirmButtonText: that.$t('确定'),
        cancelButtonText: that.$t('取消'),
        type: 'warning'
      }).then(() => {
        this.$axios.post('customer_contacts/delete', {
          id: scope.row.id,
          oc_id: scope.row.oc_id
        }).then((res) => {
          // console.log(res)
          if (res.data.code === 0) {
            this.getUserList()
            this.$message({
              type: 'success',
              message: that.$t('删除成功!')
            })
          } else this.$message.warning(res.data.message)
        }).catch((error) => console.log(error))
      }).catch(() => {
        this.$message({
          type: 'info',
          message: that.$t('已取消删除')
        })
      })
    },
    // 获取客户联系人详情
    getDetail (scope) {},
    // pageSize 改变时会触发
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`)
    },
    // currentPage 改变时会触发
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`)
      this.getUserList(val)
    }
  }
}
</script>

<style scoped>
  .Untreated_top {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .top_title {
    color: #ff9252;
  }

  ::v-deep .el-select {
    width: 100% !important;
  }

  .el-input.is-disabled::v-deep .el-input__inner {
    color: #575757;
  }
</style>
